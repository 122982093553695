import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Cookies from 'js-cookie';


const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '20px',
});

const DropZoneContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: '2px',
  borderRadius: '4px',
  borderColor: '#ddd',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
  '&:hover': {
    borderColor: '#999',
  },
});

const App = () => {
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [chatId, setChatId] = useState('');
  const [permission, setPermission] = useState(false);
  const [chatIdHistory, setChatIdHistory] = useState([]);
  const [chatDetails, setChatDetails] = useState({});
  const [currentChat, setCurrentChat] = useState('');

  const apiToken = process.env.REACT_APP_TELEBOT;
  const apiUrl = `https://api.telegram.org/bot${apiToken}/`;
  const { enqueueSnackbar } = useSnackbar();

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: true });

  const handleFileUpload = async () => {
    if (!file) {
      enqueueSnackbar('Please select a file');
      return;
    }

    const formData = new FormData();
    formData.append('chat_id', chatId);
    formData.append('document', file);

    try {
      const response = await axios.post(apiUrl + 'sendDocument', formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        },
      });
      console.log(response.data); // handle response as needed
      enqueueSnackbar('File uploaded successfully!');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Error uploading file');
    } finally {
      setProgress(0);
      setFile(null);
    }
  };

  const handleChatIdChange = (event) => {
    setChatId(event.target.value);
  };

  const handlePermissionSubmit = () => {
    setPermission(true);
    setChatIdHistory((prevChatIdHistory) => [...prevChatIdHistory, chatId]);
  };

  const handleHistoryItemClick = async (item) => {
    setChatId(item);
    setCurrentChat(await fetchChatDetails(item, ''));
    setPermission(true);
  };

  useEffect(() => {
    const chatIdHistoryFromCookies = Cookies.get('chatIdHistory');
    if (chatIdHistoryFromCookies) {
      setChatIdHistory(JSON.parse(chatIdHistoryFromCookies));
    }
  }, []);

  useEffect(() => {
    if (chatIdHistory.length > 0) {
      Cookies.set('chatIdHistory', JSON.stringify(chatIdHistory), { expires: 7 });
      chatIdHistory.map((item)=> fetchChatDetails(item,'state'))
    }
  }, [chatIdHistory]);

  const fetchChatDetails = async (chatId, state) => {
    try {
      
      const apiToken = process.env.REACT_APP_TELEBOT;
      const apiUrl = `https://api.telegram.org/bot${apiToken}/`;
      const response = await axios.get(`${apiUrl}getChat?chat_id=${chatId}`);
      const chatTitle = response.data.result.title ? response.data.result.title : chatId;
      if(state == 'state'){
        setChatDetails((prevChatDetails) => ({ ...prevChatDetails, [chatId]: chatTitle }));
      }else{
        return chatTitle;
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container>
      {permission ? (
        <>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Current Chat: {currentChat}
        </Typography>
          <DropZoneContainer {...getRootProps()}>
            <input {...getInputProps()} />
            <CloudUploadIcon sx={{ fontSize: 48 }} />
            <Typography variant="subtitle1" sx={{ mt: 1 }}>
              Drag and drop files here, or click to select files
            </Typography>
          </DropZoneContainer>
          <Button variant="contained" disabled={!file} onClick={handleFileUpload} sx={{ mt: 2 }}>
            Upload
          </Button>
          {progress > 0 && (
            <div style={{ marginTop: '10px', textAlign: 'center' }}>
              <CircularProgress variant="determinate" value={progress} />
              <Typography variant="body2" color="textSecondary" component="p">
                {`${progress}%`}
              </Typography>
            </div>
          )}
        </>
      ) : (
        <>
          <Typography variant="subtitle1" sx={{ mt: 1 }}>
            Enter Chat ID to get permission for file upload:
          </Typography>
          <TextField
            value={chatId}
            onChange={handleChatIdChange}
            label="Chat ID"
            variant="outlined"
            sx={{ mt: 2 }}
            autoComplete="off"
          />
          <Button variant="contained" onClick={handlePermissionSubmit} sx={{ mt: 2 }}>
            Get Permission
          </Button>
          {chatIdHistory.length > 0 && (
            <div>
              <Typography variant="subtitle1" sx={{ mt: 4 }}>
                Chat ID History:
              </Typography>
              {chatIdHistory.map((item) => (
                <Button
                  key={item}
                  variant="outlined"
                  onClick={() => handleHistoryItemClick(item)}
                  sx={{ mt: 2 }}
                >
                  {chatDetails[item] || (
                    <span onClick={() => fetchChatDetails(item)}>Loading...</span>
                  )}
                </Button>
              ))}
            </div>
          )}
        </>
      )}
    </Container>
  );
};

const AppWrapper = () => (
  <SnackbarProvider>
    <App />
  </SnackbarProvider>
);

export default AppWrapper;
